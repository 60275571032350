import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100`

const Form = tw.form`mt-8 text-gray-900 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 text-gray-900 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24 text-gray-900`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`


export default ({
  subheading = "Kontakt",
  heading = <>Zainteresowany współpracą?<span tw="text-primary-500"><br/> Skontaktuj się</span> z nami.</>,
  description = "Napisz do nas, odezwiemy się w ciągu 24 godzin.",
  submitButtonText = "Wyślij",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  // handle the value
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailStatus, setEmailStatus] = useState('');

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (email || phone) {
      const data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("phone", phone);
      data.append("subject", subject);
      data.append("message", message);
      try {
          let res = await fetch("mail.php", {
          method: "POST",
          body: data,
        });
        let resText = await res.text();
        if (res.status === 200) {
          setName("");
          setEmail("");
          setPhone("");
          setSubject("");
          setMessage("");
          setEmailStatus(resText);
        } else {
          setEmailStatus(resText);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setEmailStatus("Proszę podać adres email lub telefon.");
    }
  };

  return (
    <Container id="kontakt">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            
            <Form onSubmit={handleSubmit}>
              <Input type="text" className="required:border-red-500" name="name" value={name} placeholder=" imię i nazwisko" onChange={e => setName(e.target.value)} />
              <Input type="email" name="email" value={email} placeholder=" adres email" onChange={e => setEmail(e.target.value)}/>
              <Input type="text" name="telefon" value={phone} placeholder=" nr telefonu" onChange={e => setPhone(e.target.value)}/>
              <Input type="text" name="subject" value={subject} placeholder=" temat" onChange={e => setSubject(e.target.value)}/>
              <Textarea name="message" value={message} placeholder=" wiadomość" onChange={e => setMessage(e.target.value)}/>
              {emailStatus && <Description>{emailStatus}</Description>}
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
